.badge-wrapper {
  position: relative;
  font-size: 30px;
}

.badge-notify {
  position: absolute;
  top: -2px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  font-size: 12px;
  color: white;
}

.notify-cursor {
  cursor: pointer;
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-right {
  display: flex;
  justify-content: end !important;
  align-items: center;
  margin-bottom: 20px;
}

.address-btn {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.edit-img {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;

}
.edit-img img {
  display: block;
  min-height: 150px;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  border-radius: 50% !important;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

/* When you mouse over the container, fade in the overlay title */
.edit-img:hover .overlay {
  opacity: 1;
}
.scrollable-content {
  height: 200px;
}

#file-upload {
  font-family: calibri;
  padding: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border: 1px dashed #bbb;
  text-align: center;
  cursor: pointer;
}

/* 
.scrollable-content {
  overflow-x:hidden;
  overflow-y:scroll; 
}
.scrollable-content::-webkit-scrollbar {
  width:30px;
}
.scrollable-content::-webkit-scrollbar * {
  background:transparent; 
}
.scrollable-content::-webkit-scrollbar-thumb {
  background:rgba(255,0,0,0.1) !important; 
} */
