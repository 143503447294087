@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: 1.4;
  color: #202020;
  font-family: "Montserrat", sans-serif;
}
.h2,
h2 {
  font-size: 36px;
  font-weight: 600;
}
button:focus {
  outline: none;
}
button {
  outline: none;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
a {
  text-decoration: none;
  color: #004187;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-family: "Montserrat", sans-serif;
}
a:hover {
  color: #9a75e2;
  text-decoration: none;
}
.btn {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #202020;
  background: #fff;
  font-weight: 400;
  padding-top: 90px;
}
img {
  max-width: 100%;
}
.sec {
  padding: 70px 0;
}
.container {
  max-width: 1470px;
}
p {
  margin-bottom: 15px;
}
/*---------------------header-------------------*/
.header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 0;
  z-index: 999;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: #fff;
  box-shadow: 0 0 6px rgb(0 0 0 / 15%);
}
.top-header {
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: space-between;
}
.main-nav {
  display: flex;
  align-items: center;
}
.main-nav ul {
  margin: 0 -15px;
  padding: 0;
}
.main-nav li {
  display: inline-block;
  padding: 0 20px;
  position: relative;
}

.main-nav li a {
  padding: 15px 0;
  display: block;
  font-size: 16px;
  color: #0f0f0f;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.main-nav li a:after {
  content: "";
  position: absolute;
  background: #203f5e;
  width: 2px;
  height: 3px;
  bottom: 5px;
  left: 50%;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.logo {
  width: 160px;
}
.top-search {
  width: 280px;
  position: relative;
  margin-left: 50px;
}
.top-search input[type="text"] {
  width: 100%;
  border: 1px solid #d1d1d1;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  padding: 9px 15px;
  border-radius: 6px;
  font-weight: 500;
  outline: none;
}
.btn-right {
  max-width: 260px;
  display: flex;
  align-items: center;
  margin-left: 50px;
}

.main-nav li a:hover {
  color: #203f5e;
}

.main-nav > ul > li:hover > a:after,
.main-nav > ul > li > a.active:after {
  left: 0;
  opacity: 1;
  width: 100%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.main-nav li a.active {
  color: #203f5e;
  font-weight: 600;
}
li.has-sub ul {
  position: absolute;
  background: #fff;
  border-radius: 0 0 8px 8px;
  height: 0;
  visibility: hidden;
  overflow: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0 0 6px #ddd;
  min-width: 180px;
}
.main-nav li.has-sub:hover ul {
  height: auto;
  visibility: visible;
}
li.has-sub ul li {
  display: block;
  margin: 0;
  padding: 0;
  border-top: 1px solid #dbd8d8;
}
li.has-sub ul li a {
  padding: 9px 15px;
  font-size: 14px;
}
.main-nav .custom-btn {
  color: #e52554;
  background: none;
  border: 2px solid #e52554;
  font-size: 18px;
  padding: 12px 30px;
  border-radius: 30px;
  text-transform: uppercase;
}
span.submenu-button {
  width: 9px;
  height: 9px;
  border-right: 2px solid #0f0f0f;
  position: absolute;
  border-bottom: 2px solid #0f0f0f;
  transform: rotate(45deg);
  top: 50%;
  margin-top: -6px;
  right: 2px;
}
.nav-right {
  /*width: calc(100% - 145px);*/
  display: flex;
  align-items: center;
  justify-content: end;
  padding-left: 160px;
}
.top-search-btn {
  position: absolute;
  border: none;
  background: none;
  color: rgb(32 32 32 / 75%);
  top: 8px;
  right: 8px;
  font-weight: 300;
}
.custom-btn {
  border: 1px solid #203f5e;
  font-size: 16px;
  font-weight: 600;
  color: #203f5e;
  letter-spacing: 0.5px;
  padding: 10px 25px;
  border-radius: 6px;
  min-height: 46px;
}

.custom-btn:hover {
  border: 1px solid #203f5e;
  color: #fff;
  background: #203f5e;
}
.btn-right .custom-btn {
  margin-left: 10px;
}
.wallet-btn {
  background: #203f5e;
  background: -moz-linear-gradient(45deg, #203f5e 0%, rgb(128 163 203) 100%);
  background: -webkit-linear-gradient(45deg, #203f5e 0%, rgb(128 163 203) 100%);
  background: linear-gradient(45deg, #203f5e 0%, rgb(128 163 203) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003056', endColorstr='#9cb6d3',GradientType=1 );
  color: #fff;
  border: none !important;
}
.wallet-btn:hover {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#9cb6d3+0,003056+100 */
  background: rgb(156, 182, 211); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgb(128 163 203) 0%,
    #203f5e 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgb(128 163 203) 0%,
    #203f5e 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgb(128 163 203) 0%,
    #203f5e 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9cb6d3', endColorstr='#003056',GradientType=0 ); /* IE6-9 */

  color: #fff;
  border: none !important;
}

.home-banner {
  background: url(../images/banner-bg.png) no-repeat right center #51c6f3;
  position: relative;
  padding: 90px 0;
}
.home-banner:before {
  background: url(../images/art-img.png) no-repeat right bottom;
  width: 130px;
  height: 114px;
  position: absolute;
  content: "";
  left: 10px;
  bottom: 25px;
}
.banner-img {
  background: #003056;
  margin: 40px 0 0 50px;
}
.banner-img img {
  width: 100%;
  display: block;
  top: -40px;
  position: relative;
  left: -40px;
}
.banner-img video {
  width: 100%;
  display: block;
  top: -40px;
  position: relative;
  left: -40px;
  object-fit: cover;
}

.banner-caps h1 {
  font-size: 60px;
  font-weight: 800;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 20px;
  line-height: 1.2;
  max-width: 650px;
}
.banner-caps p {
  font-size: 21px;
  color: #fff;
  margin-bottom: 25px;
}
.banner-caps ul {
  margin: 0 -25px;
  padding: 0;
  display: flex;
}
.banner-caps ul li {
  text-align: center;
  display: block;
  font-size: 21px;
  font-weight: 600;
  color: #fff;
  padding: 0 25px 35px;
  margin-left: 35px;
  font-family: "Montserrat", sans-serif;
}
.banner-caps ul li span {
  display: block;
  font-size: 17px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.banner-caps ul li:first-child {
  margin: 0;
}
.btn-fill {
  background: #003056;
  color: #fff;
}
.banner-btn .btn-fill {
  font-size: 18px;
  font-weight: 500;
  min-width: 143px;
  margin-right: 25px;
}
.v-btn {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}
.v-btn i {
  color: #ff0000;
  font-size: 36px;
  vertical-align: middle;
  margin-right: 5px;
}
.v-btn:hover {
  color: #ff0000;
}
.banner-caps .banner-btn {
  margin: 0;
}
h2.sec-head {
  font-size: 30px;
  color: #55565a;
  font-weight: 700;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}
h2.sec-head:after {
  position: absolute;
  content: "";
  height: 3px;
  width: 100px;
  background: #74a8db;
  bottom: -13px;
  left: 50%;
  margin-left: -50px;
}
.create-sell-col {
  background: #f9fafd;
  border: 1px solid transparent;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 0 8px rgb(0 0 0 / 16%);
  padding: 25px 20px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  cursor: pointer;
  margin-top: 30px;
}
.create-sell-col:hover {
  border: 1px solid #5d89b4;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0 0 12px rgb(0 0 0 / 20%);
}
.create-sell-icon {
  padding: 10px;
  margin-bottom: 10px;
}
h4 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
}
.create-sell-col p {
  color: #5e5e5e;
  margin: 0 0 5px;
  font-size: 15px;
}
.sub-head {
  text-align: center;
  margin: 0 auto 25px;
  max-width: 1000px;
  font-size: 18px;
}
.timeline-col-con p {
  color: #565656;
}
.trending-cat {
  background: #f1f2fb;
}
.trending-cat-col {
  background: #fff;
  border-radius: 18px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 8px rgb(0 0 0 / 15%);
  margin: 5px 5px 35px;
}
.trending-cat-col:hover {
  cursor: pointer;
}
.like {
  position: absolute;
  background: #4d2f61;
  border-radius: 50px;
  color: #fff;
  padding: 4px 10px 3px;
  left: 17px;
  top: 13px;
  font-size: 10px;
}
.like i {
  color: #fc5353;
  margin-right: 3px;
  text-shadow: 0 0 2px #fff;
}
.trending-cat-img {
  /* max-height: 345px; */
  overflow: hidden;
}
.trending-cat-img img {
  width: 100%;
  display: block;
  /* margin-top: -10px; */
}

.trending-cat-img-detail img {
  width: 100%;
}

.trending-cat-img-detail img:hover {
  transform: scale(1.1);
}

.trending-cat-det {
  padding: 20px;
}
.det-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.user-icon-l {
  position: relative;
}
.user-icon-l > img {
  width: 38px;
  height: 38px;
  padding: 0px;
  border: none;
  border-radius: 50%;
  margin-right: 5px
}
.user-id {
  color: #003056;
  font-weight: 300;
}
.user-check {
  position: absolute;
  width: 13px;
  right: 10px;
  top: -13px;
}
.price {
  font-size: 18px;
  color: #59d386;
  font-weight: 700;
  border: 2px solid #59d386;
  position: relative;
  display: block;
  padding: 6px 20px;
  border-radius: 15px;
}
.price span {
  font-size: 12px;
  color: #8a939b;
  font-weight: 500;
  position: absolute;
  left: 18px;
  top: -13px;
  background: #fff;
  padding: 2px;
}
.pro-name {
  font-size: 19px;
  color: #5d89b4;
  font-weight: 600;
}
.unit {
  color: #8a939b;
  font-size: 15px;
}
.trending-cat-det .custom-btn {
  font-size: 14px;
  min-height: 40px;
}

.trending-cat-col:before {
  top: 0;
  right: 0;
  content: "";
  display: block;
  width: 213px;
  height: 64px;
  position: absolute;
  /* background: url(../images/img-shape.png) no-repeat left top; */
}
.v-all {
  font-size: 18px;
  min-height: 50px;
  margin: 10px;
}
.tab-menu {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  margin-bottom: 15px;
}
.tab-menu ul {
  margin: 0;
  padding: 0;
  text-align: center;
  box-shadow: 0 0 7px rgb(0 0 0 / 16%);
  display: flex;
  border-radius: 8px;
}
.tab-menu ul li {
  list-style-type: none;
  display: block;
  margin: 0 15px;
}
.tab-menu ul li:first-child,
.tab-menu ul li:last-child {
  margin: 0;
}
.tab-menu ul li a {
  text-decoration: none;
  color: #003056;
  padding: 12px 35px;
  border-radius: 8px;
  font-weight: 600;
  display: block;
}
.tab-menu ul li a.active-a {
  background: #203f5e;
  background: -moz-linear-gradient(45deg, #203f5e 0%, rgb(128 163 203) 100%);
  background: -webkit-linear-gradient(45deg, #203f5e 0%, rgb(128 163 203) 100%);
  background: linear-gradient(45deg, #203f5e 0%, rgb(128 163 203) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003056', endColorstr='#9cb6d3',GradientType=1 );
  color: #fff;
}
.tab-menu ul li span {
  text-decoration: none;
  color: #003056;
  padding: 12px 35px;
  border-radius: 8px;
  font-weight: 600;
  display: block;
}
.tab-menu ul li span.active-a {
  background: #203f5e;
  background: -moz-linear-gradient(45deg, #203f5e 0%, rgb(128 163 203) 100%);
  background: -webkit-linear-gradient(45deg, #203f5e 0%, rgb(128 163 203) 100%);
  background: linear-gradient(45deg, #203f5e 0%, rgb(128 163 203) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003056', endColorstr='#9cb6d3',GradientType=1 );
  color: #fff;
}
.tab {
  display: none;
}
.tab h2 {
  color: rgba(0, 0, 0, 0.7);
}
.tab p {
  color: rgba(0, 0, 0, 0.6);
  text-align: justify;
}
.tab-active {
  display: block;
}
.colection-box {
  background: #fff;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  position: relative;
  overflow: hidden;
  border-radius: 0 0 20px 20px;
  margin: 5px 5px 35px;
}
.colection-img img {
  width: 100%;
}
.medal {
  position: absolute;
  background: #fffbfe;
  top: 12px;
  left: 20px;
  padding: 5px 13px;
  font-size: 11px;
  font-weight: 600;
  border-radius: 15px;
}
.medal img {
  margin-right: 1px;
}
.colection-det {
  padding: 20px;
}
.col-name {
  color: #55565a;
  font-weight: 600;
  margin-right: 5px;
}
.follow-btn {
  font-size: 12px;
  min-height: auto;
  padding: 5px 12px;
  border-radius: 4px;
}
.visiter {
  color: #8a939b;
  font-size: 14px;
}
.user-center {
  display: block;
  width: 70px;
  height: 70px;
  border: 6px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  margin: -32px auto 0;
  position: relative;
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
}
.activity-carousel .user-icon-l > img {
  width: 38px;
  padding: 3px;
  border: 1px solid #5d89b4;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
}
.activity-carousel .trending-cat-col:before {
  opacity: 0.2;
}
.activity-carousel .like {
  display: none;
}
.activity-carousel .trending-cat-col {
  border-radius: 0 0 30px 30px;
  margin: 5px;
}
.activity-slider {
  padding-top: 15px;
}
.owl-nav i {
  font-weight: 400;
}
.activity-slider button.owl-next {
  background: #fff !important;
  position: absolute;
  right: 5px;
  top: 50%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 26px !important;
  text-align: center;
  line-height: 42px !important;
  margin-top: -40px;
}
.activity-slider button.owl-prev {
  background: #fff !important;
  position: absolute;
  left: 5px;
  top: 50%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 26px !important;
  text-align: center;
  line-height: 42px !important;
  margin-top: -40px;
}
.timeline-col-right {
  width: 50%;
  padding: 15px;
}
.timeline-col-left {
  width: 50%;
  padding: 15px;
}
.timeline-col-in {
  display: flex;
  padding: 20px;
  box-shadow: 0 0 8px rgb(0 0 0 / 16%);
  border-radius: 6px;
  margin: 25px;
}
.timeline-col-con {
  width: calc(100% - 160px);
  padding-left: 25px;
}
.timeline-col-img {
  background: #fff;
  border: 1px solid #5d89b4;
  padding: 7px;
  border-radius: 7px;
  width: 160px;
  display: flex;
  height: 200px;
}
.timeline-col-img img {
  border-radius: 6px;
}
.timeline-date {
  margin: 25px;
  display: flex;
  align-items: center;
  padding: 20px;
  position: relative;
  justify-content: end;
}
.timeline-time-icon {
  background: #5d89b4;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 15px;
}
.timeline-time-text p {
  margin: 0;
  font-size: 16px;
  text-align: center;
}
.timeline-time-text p span {
  display: block;
}
.timeline-time-text .c-date {
  font-size: 18px;
  font-weight: 500;
}
.timeline {
  padding: 0 50px;
  position: relative;
}
.flex-row-reverse .timeline-date {
  justify-content: flex-start;
}
.timeline:after {
  content: "";
  position: absolute;
  width: 1px;
  left: 50%;
  top: -17px;
  bottom: -10px;
  background: #ddd;
  z-index: 1;
}
.timeline-date:after {
  content: "";
  position: absolute;
  right: -52px;
  top: 40%;
  width: 22px;
  height: 22px;
  background: #b0ff61;
  border: 2px solid #fff;
  box-shadow: 0 0 4px rgb(0 0 0 / 15%);
  border-radius: 50%;
  z-index: 9;
}
.flex-row-reverse .timeline-date:after {
  right: auto;
  left: -50px;
}
.timeline:after {
  content: "";
  position: absolute;
  width: 2px;
  left: 50%;
  top: -17px;
  bottom: -10px;
  background: #ececec;
  z-index: 1;
}
ul.accordion-list {
  position: relative;
  display: block;
  max-width: 1200px;
  height: auto;
  padding: 0;
  margin: 0 auto;
  list-style: none;
}
ul.accordion-list li {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  background-color: rgb(156 182 211 / 25%);
  padding: 20px;
  margin: 0 0 20px;
  border: 1px solid #c5d2df;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}
ul.accordion-list li.active h3:after {
  transform: rotate(180deg);
}
ul.accordion-list li h3 {
  font-weight: 600;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding-right: 16px;
  margin: 0;
  font-size: 17px;
  letter-spacing: 0.01em;
  cursor: pointer;
  color: #000;
}
ul.accordion-list li h3:after {
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  right: 0;
  top: 0;
  color: #000;
  transition: all 0.3s ease-in-out;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
}
ul.accordion-list li div.answer {
  position: relative;
  display: block;
  height: auto;
  margin: 0 -20px;
  padding: 15px 20px 10px;
  cursor: pointer;
  border-top: 1px solid #ddd;
  top: 18px;
}

ul.accordion-list li div.answer p {
  position: relative;
  display: block;
  font-weight: 300;
  padding: 10px 0 0 0;
  cursor: pointer;
  line-height: 150%;
  margin: 0 0 15px 0;
  font-size: 15px;
}
ul.accordion-list li.active {
  background: #fff;
}
.footer {
  background: #003056;
  padding: 60px 0 20px;
}
.footer h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}
.footer ul {
  margin: 0;
  padding: 0;
}
.footer ul li {
  display: block;
}
.footer ul li a {
  color: #fff;
  display: inline-block;
  padding: 4px 0;
}
.footer ul li a:hover {
  color: rgb(255 255 255 / 50%);
}
.footer p {
  color: #fff;
}
.subscribe-box {
  display: flex;
  padding: 15px 0;
  position: relative;
}
.subscribe-box .custom-btn {
  background: #9cb6d3;
  padding: 10px 16px;
  border: none;
}
.subscribe-box input[type="text"] {
  background: #fff;
  padding: 0 15px;
  border: none;
  border-radius: 6px 0 0 6px;
  width: calc(100% - 115px);
  margin-right: -7px;
}
.footer-bottom {
  border-top: 1px solid rgb(221 221 221 / 28%);
  padding-top: 25px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-left span {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  margin-left: 35px;
  font-family: "Montserrat", sans-serif;
}
.footer-left span strong {
  font-weight: 600;
}
.footer-right a {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  border-radius: 50%;
  margin-left: 5px;
  color: #fff;
}
.footer-right a:hover {
  opacity: 0.6;
}
.social-1 {
  background: #00a7e6;
}
.social-2 {
  background: #1773ea;
}
.social-3 {
  background: #c82e87;
}
.social-4 {
  background: #ff0000;
}
.social-5 {
  background: #7289da;
}

/*--------------------------------------------*/
ul.accordion-list1 {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0;
  overflow: hidden;
}
ul.accordion-list1 li {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding: 20px;
  margin: 0;
  border-top: 1px solid #ddd;
  cursor: pointer;
}
ul.accordion-list1 li.active h3:after {
  transform: rotate(180deg);
}
ul.accordion-list1 li h3 {
  font-weight: 600;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding-right: 16px;
  margin: 0;
  font-size: 16px;
  letter-spacing: 0.01em;
  cursor: pointer;
  color: #000;
}
ul.accordion-list1 li h3:after {
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  right: 0;
  top: 0;
  color: #000;
  transition: all 0.3s ease-in-out;
  font-size: 20px;
  font-weight: 400;
}
ul.accordion-list1 li div.answer {
  position: relative;
  display: block;
  height: auto;
  margin: 20px -20px 0;
  padding: 20px 20px 0;
  border-top: 1px solid #ddd;
}

ul.accordion-list1 li.active {
  background: rgb(156 182 211 / 9%);
}
.filter-box h4 {
  font-size: 18px;
  padding: 20px;
  margin: 0;
  font-weight: 700;
}
.page-banner {
  background: rgb(156 182 211 / 26%);
  text-align: center;
  padding: 80px 0;
}
.page-banner h2 {
  font-size: 30px;
  font-weight: 700;
  color: #5d89b4;
}
ul.bredcrumb {
  margin: 0;
  padding: 0;
}
ul.bredcrumb li {
  display: inline-block;
  padding: 0 10px;
  color: #5d89b4;
  position: relative;
}
ul.bredcrumb li a {
  color: #202020;
}
ul.bredcrumb li:after {
  position: absolute;
  content: "";
  width: 1.5px;
  height: 15px;
  background: #727272;
  right: -3px;
  top: 5px;
  transform: rotate(25deg);
}
ul.bredcrumb li:last-child:after {
  display: none;
}
.filter-box {
  border: 1px solid #ddd;
  padding: 0;
}
.filter-box h4 img {
  margin-right: 5px;
}
.adm-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.adm-icon {
  display: inline-block;
  width: 35px;
  margin-right: 5px;
}
.adm-right {
  font-weight: 600;
  padding-left: 15px;
}
.p-tag a {
  color: rgb(0 48 86 / 80%);
  border: 1px solid rgb(0 48 86 / 50%);
  display: inline-block;
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 6px;
  margin: 0 5px 10px;
}
.p-tag a:hover {
  color: #fff;
  border: 1px solid rgb(0 48 86 / 80%);
  background: rgb(0 48 86 / 80%);
}
.top-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.top-filter h4 {
  color: #55565a;
  font-size: 21px;
}

.filter-form {
  display: flex;
  align-items: center;
  margin: 0 -10px;
}
.filter-form-feild {
  width: 220px;
  padding: 0 10px;
  position: relative;
}
.filter-form-feild .form-control {
  font-size: 14px;
  padding: 9px 15px;
  height: auto;
  border: 1px solid #5d89b4;
}
.filter-form-feild button {
  position: absolute;
  right: 16px;
  top: 7px;
  border: none;
  background: none;
  color: #666;
}
.filter-list .trending-cat-col:before {
  display: none;
}
.filter-list .trending-cat-img {
  max-height: 250px;
  overflow: hidden;
}
.filter-list .trending-cat-col {
  margin: 5px 0 30px;
}
.pagination ul {
  display: flex;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.pagination ul li {
  display: block;
  padding: 10px;
}
.pagination ul li a {
  font-weight: 600;
  color: #202020;
  font-size: 20px;
  display: block;
  padding: 3px 10px;
  width: 38px;
  height: 38px;
  border-radius: 6px;
}
.pagination ul li i {
  font-size: 28px;
}
.pagination ul li a.active,
.pagination ul li a:hover {
  background: #203f5e;
  background: -moz-linear-gradient(45deg, #203f5e 0%, rgb(128 163 203) 100%);
  background: -webkit-linear-gradient(45deg, #203f5e 0%, rgb(128 163 203) 100%);
  background: linear-gradient(45deg, #203f5e 0%, rgb(128 163 203) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003056', endColorstr='#9cb6d3',GradientType=1 );
  color: #fff;
}
.pagination {
  margin-top: 10px;
}

/*--------------------------login----------------------*/
.login-box a {
  color: #5d89b4;
  font-weight: 600;
}
.login-box {
  box-shadow: 0 0 8px rgb(0 0 0 / 16%);
  height: 100%;
  padding: 40px;
  border-radius: 24px;
  margin: 0 15px;
}
.login-box h2 {
  text-align: center;
  color: #5d89b4;
  font-weight: 700;
  margin-bottom: 25px;
}
.log-with a {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  border-radius: 50%;
  margin-left: 5px;
  color: #fff;
}
.log-with {
  text-align: center;
}
.login-box p {
  text-align: center;
}
.login-form-feild .form-control {
  padding: 14px 18px;
  background: #fbfdff;
  border: 1px solid #f2f2f2;
  outline: none;
  border-radius: 6px;
}
.styled-checkbox {
  position: absolute;
  opacity: 0;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  line-height: 26px;
  color: #5d89b4;
  font-weight: 500;
}
.styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  width: 26px;
  height: 26px;
  background: white;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
}
.styled-checkbox:hover + label:before {
  background: #f35429;
}
.styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.styled-checkbox:checked + label:before {
  background: #f35429;
}
.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
    4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}
.rem {
  position: relative;
}
.login-form-feild {
  position: relative;
  margin-bottom: 25px;
}
.rem-pass {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-form-feild .custom-btn {
  width: 100%;
  padding: 16px 25px;
  font-size: 18px;
}
.login-form {
  padding: 15px 10px 0;
}
.login-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-img video {
  height: 550px;
  object-fit: cover;
}
.exp-detail-left .trending-cat-col:before {
  display: none;
}
.exp-detail-left .trending-cat-img {
  max-height: max-content;
}
.exp-detail-left .trending-cat-img {
  max-height: 530px;
}
.adm-token {
  font-size: 11px;
  background: #ddd;
  color: #003056;
  display: inline-block;
  padding: 7px 10px;
  border-radius: 4px;
}
.exp-top-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.exp-top-col ul {
  margin: 0;
  padding: 0;
  display: flex;
  border: 1px solid #ddd;
  border-radius: 6px;
}
.exp-top-col ul li {
  display: block;
  padding: 4px 7px;
  border-left: 1px solid #ddd;
  width: 42px;
  text-align: center;
}
.exp-top-col ul li:first-child {
  border: none;
}
.exp-top-col ul li a {
  color: #202020;
}
.ext-name-tit {
  /* border-bottom: 1px solid #ddd; */
  padding: 30px 0;
  margin-bottom: 25px;
}
.ext-name-tit h4 {
  font-size: 20px;
  color: #5d89b4;
}
.ext-name-tit h3 {
  color: #55565a;
  margin-bottom: 15px;
}
.ext-name-tit-row {
  display: flex;
  align-items: center;
}
.ext-name-tit-row a {
  color: rgb(32 32 32 / 75%);
  padding: 0 6px;
  margin-left: 0px;
}
.btn-icon {
  display: inline-block;
  overflow: hidden;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 5px;
}
.exp-btn-g .btn {
  margin-right: 10px;
}
.exp-btn-g .border-btn {
  padding: 9px 18px;
}
.ext-name-tit .trending-cat-det {
  padding: 35px 0;
}
.price small {
  color: #8a939b;
  margin: 0 5px;
}
.ext-name-tit .trending-cat-det .price {
  display: inline-block;
  padding: 9px 25px;
}
.ext-name-tit .wallet-btn {
  padding: 10px 35px;
}
.exp-detail-left {
  padding-right: 30px;
}
.exp-detail-right {
  padding-left: 30px;
}
.exp-detail-left ul.accordion-list1 li {
  background: rgb(156 182 211 / 35%);
  border: 1px solid rgb(156 182 211 / 55%);
  margin-bottom: 25px;
  border-radius: 6px;
  padding: 15px;
  color: #55565a;
  overflow: hidden;
}
.exp-detail-left ul.accordion-list1 li div.answer p:last-child {
  margin: 0;
}
.exp-detail-left ul.accordion-list1 li div.answer {
  position: relative;
  display: none;
  height: auto;
  margin: 20px -20px -15px;
  padding: 20px 20px 20px;
  border-top: 1px solid rgb(156 182 211 / 55%);
  background: #fff;
}
.exp-detail-left ul.accordion-list1 li div.answer p {
  font-size: 15px;
}
.bid-histry {
  font-size: 12px;
  color: #003056;
  border: 1px solid #5d89b4;
  padding: 6px 15px;
  display: inline-block;
  border-radius: 6px;
  margin-bottom: 15px;
}
.bid-date {
  display: block;
  font-size: 12px;
  color: #7e7e7e;
}
.bid-h-row {
  display: flex;
  padding-top: 5px;
}
.resent-histry {
  padding: 50px 0;
}
.resent-histry tbody {
  background: #fbfdff;
}
.resent-histry tbody .img-icon {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  padding: 2px;
  border: 1px solid #5d89b4;
}
.resent-histry tbody td span {
  font-size: 12px;
  color: #666;
  display: block;
}
.resent-histry tbody td {
  padding: 5px 25px;
  vertical-align: middle;
  border: none;
  border-bottom: 1px solid #dee2e6;
}
.resent-histry thead th {
  padding: 10px 25px;
  vertical-align: middle;
  border: none;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
.resent-histry .table-responsive {
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}
.share-btn {
  display: inline-block;
  border: 1px solid #ddd;
  color: rgb(32 32 32 / 75%);
  font-size: 14px;
  padding: 0 6px;
  border-radius: 4px;
  margin: 0 7px;
}
.resent-histry h2.sec-head {
  margin-bottom: 50px;
}
.collectin-img {
  padding: 0;
  position: relative;
  margin: 30px;
}
.collectin-img img {
  width: 100%;
  border-radius: 12px;
  max-height: 400px;
  overflow: hidden;
  position: relative;
}
.collectin-img:before {
  content: "";
  position: absolute;
  background: #5d89b4;
  left: -25px;
  top: 25px;
  right: 25px;
  bottom: -25px;
  border-radius: 12px;
}
.flex-row-reverse .collectin-img:before {
  left: 25px;
  top: 25px;
  right: -25px;
  bottom: -25px;
  border-radius: 12px;
}
.profile-row {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  box-shadow: 0 0 12px rgb(0 0 0 / 16%);
  border-radius: 12px;
  margin-bottom: 50px;
}

.item-row {
  flex-wrap: wrap;
  padding: 30px;
  box-shadow: 0 0 12px rgb(0 0 0 / 16%);
  border-radius: 12px;
  margin-bottom: 50px;
}

.profile-col-left {
  width: calc(100% - 270px);
  display: flex;
  flex-wrap: wrap;
}
.profile-img-l {
  width: 210px;
  border-radius: 10px;
  overflow: hidden;
}
.profile-detail-l {
  width: calc(100% - 210px);
  padding: 0 30px;
}
.prof-seb {
  font-size: 18px;
}
.prof-btn {
  display: inline-block;
  background: #dddddd;
  color: #003056;
  font-weight: 600;
  padding: 9px 15px;
  border-radius: 6px;
}
.prof-btn-group {
  margin: 0;
}
.profile-col-right .exp-top-col {
  margin-bottom: 15px;
}
.collectin-con {
  margin-bottom: 20px;
}
.wallet-box {
  max-width: 900px;
  margin: 0 auto;
}
ul.wallet-list {
  margin: 0 0 40px;
  padding: 0;
}
ul.wallet-list li {
  display: block;
  border: 1px solid #ddd;
  padding: 10px 18px;
  border-radius: 8px;
  margin-bottom: 20px;
}
ul.wallet-list li a {
  font-size: 22px;
  color: #202020;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.wallet-icon {
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
  justify-content: center;
  box-shadow: 0 0 3px rgb(0 0 0 / 20%);
  border-radius: 50%;
  padding: 10px;
  margin-right: 15px;
  background: #fff;
}
ul.wallet-list li.active,
ul.wallet-list li:hover {
  background: #f1f1f1;
}
.wallet-row .login-img video {
  height: 435px;
  object-fit: cover;
}
.wallet-row {
  padding-top: 20px;
}

.logoImg {
  border-radius: 50% !important;
  width: 160px;
  height: 160px;
}

.bid-input {
  position: relative;
  display: block;
  padding-right: 76px;
  width: 174px;
  box-sizing: border-box;
}

.bid-input::after {
  content: '.' attr(data-domain);
  position: absolute;
  top: 4px;
  left: 96px;
  font-family: arial, helvetica, sans-serif;
  font-size: 12px;
  display: block;
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
}