@media only screen and (min-width: 2000px) {
}

@media only screen and (min-width: 1300px) {
}

@media only screen and (max-width: 1599px) {
  .nav-right {
    padding-left: 50px;
  }
  .btn-right {
    max-width: 310px;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .banner-caps h1 {
    font-size: 60px;
  }
  h4 {
    font-size: 20px;
  }
  body {
    font-size: 15px;
  }
  .login-img video {
    height: 520px;
    object-fit: cover;
  }
  .container {
    max-width: 1470px;
    width: 90%;
}
}

@media only screen and (max-width: 1360px) {
  .top-search {
    width: 230px;
    margin-left: 30px;
  }
  .nav-right {
    padding-left: 30px;
  }
  .main-nav li {
    padding: 0 14px;
  }
  .page-banner {
    padding: 50px 0;
  }
  .filter-box h4 {
    font-size: 18px;
    padding: 15px;
  }
  ul.accordion-list1 li {
    padding: 15px;
  }
  .sub-head {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1259px) {
  .top-search {
    margin-left: 25px;
  }
  .top-search input[type="text"] {
    font-size: 14px;
    padding: 8px 15px;
  }
  .nav-right {
    padding-left: 25px;
  }
  .custom-btn {
    font-size: 14px;
    padding: 8px 17px;
    border-radius: 8px;
    min-height: 39px;
  }
  .btn-right {
    max-width: 258px;
    margin-left: 0;
  }
  .main-nav li a {
    padding: 15px 0;
    font-size: 14px;
  }
  .main-nav li {
    padding: 0 13px;
  }
  .top-search {
    width: 160px;
    position: relative;
    margin-left: 10px;
  }
  .banner-img video {
    top: -40px;
    left: -40px;
    height: 320px;
  }
  .banner-caps h1 {
    font-size: 48px;
  }
  .home-banner {
    padding: 50px 0;
  }
  .banner-caps ul li {
    font-size: 18px;
    padding: 0 20px 35px;
    margin-left: 0;
  }
  .banner-caps ul li span {
    font-size: 15px;
  }
  .create-sell-col {
    margin-top: 30px;
    height: calc(100% - 30px);
  }
  .price {
    font-size: 15px;
    padding: 5px 15px;
    border-radius: 15px;
  }
  .trending-cat-det {
    padding: 15px;
  }
  .unit {
    font-size: 13px;
  }
  .pro-name {
    font-size: 16px;
  }
  .v-all {
    font-size: 16px;
    min-height: 42px;
    margin: 10px;
  }
  .colection-det {
    padding: 15px;
  }
  .follow-btn {
    font-size: 12px;
    min-height: auto;
    padding: 4px 10px;
  }
  .timeline {
    padding: 0;
  }
  .timeline-col-in {
    padding: 15px;
    margin: 10px;
  }
  .timeline-date {
    margin: 0;
    padding: 15px;
  }
  .timeline-date:after {
    right: -27px;
  }
  .flex-row-reverse .timeline-date:after {
    right: auto;
    left: -25px;
  }
  ul.accordion-list li h3 {
    font-size: 15px;
  }
  span.submenu-button {
    width: 8px;
    height: 8px;
    border-right: 1px solid #0f0f0f;
    border-bottom: 1px solid #0f0f0f;
    right: 0;
  }
  .login-box h2 {
    margin-bottom: 20px;
    font-size: 26px;
  }
  .login-form-feild .form-control {
    padding: 11px 18px;
    border-radius: 6px;
    font-size: 15px;
  }
  .login-form-feild {
    position: relative;
    margin-bottom: 15px;
  }
  .login-form {
    padding: 0;
  }
  .login-form-feild .custom-btn {
    width: 100%;
    padding: 13px 25px;
    font-size: 16px;
  }
  .login-box {
    padding: 25px;
    border-radius: 18px;
    margin: 0 5px;
  }
  .login-img video {
    height: 435px;
    object-fit: cover;
  }
  .login-box p:last-child {
    margin: 0;
  }
  .profile-detail-l p {
    margin-bottom: 10px;
    line-height: 1.4;
  }
  .profile-detail-l .prof-btn-group {
    margin: 0;
  }
  .profile-detail-l {
    width: calc(100% - 210px);
    padding: 0 25px;
  }
  .profile-row {
    padding: 20px;
  }
  .exp-detail-left {
    padding-right: 0;
  }
  .exp-detail-right {
    padding-left: 0;
  }
  .ext-name-tit {
    /* border-bottom: 1px solid #ddd; */
    padding: 20px 0;
    margin-bottom: 0;
  }
  .ext-name-tit h4 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .ext-name-tit-row a {
    padding: 0 6px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .sec {
    padding: 30px 0;
  }

  .main-nav {
    width: 100%;
    padding-right: 0;
    position: absolute;
    left: 0;
    top: 75px;
  }
  .main-nav > ul {
    right: 0;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: #004187;
    padding-top: 0;
    z-index: 99;
    margin-top: 10px;
  }
  .main-nav ul li {
    width: 100%;
    border-top: 1px solid rgb(255 255 255 / 20%);
    padding: 0 10px;
  }
  /*.main-nav ul li:hover{background:#363636;}*/
  .main-nav ul ul li,
  .main-nav li:hover > ul > li {
    height: auto;
  }
  .main-nav ul li a,
  .main-nav ul ul li a {
    width: 100%;
    border-bottom: 0;
    color: #fff;
  }
  .main-nav > ul > li {
    float: none;
    margin: 0;
  }
  .main-nav ul ul li a {
    padding-left: 25px;
  }
  .main-nav ul ul li {
    background: none !important;
  }
  .main-nav ul ul li:hover {
    background: #363636 !important;
  }
  .main-nav ul ul ul li a {
    padding-left: 35px;
  }
  .main-nav ul ul li a {
    color: #fff;
    background: none;
  }
  .main-nav ul ul li:hover > a,
  .main-nav ul ul li.active > a {
    color: #fff;
  }
  .main-nav ul ul,
  .main-nav ul ul ul {
    position: relative;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: left;
  }
  .main-nav > ul > li.has-sub > a:after,
  .main-nav > ul > li.has-sub > a:before,
  .main-nav ul ul > li.has-sub > a:after,
  .main-nav ul ul > li.has-sub > a:before {
    display: none;
  }
  .main-nav .button {
    display: block;
    width: 40px;
    height: 38px;
    position: absolute;
    right: 15px;
    top: -55px;
    cursor: pointer;
    z-index: 12399994;
    background: #004187;
    border-radius: 8px;
  }
  .main-nav .button:after {
    position: absolute;
    top: 19px;
    right: 10px;
    display: block;
    height: 9px;
    width: 20px;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    content: "";
  }

  .main-nav .button:before {
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: absolute;
    top: 11px;
    right: 10px;
    display: block;
    height: 2px;
    width: 20px;
    background: #fff;
    content: "";
  }
  .main-nav .button.menu-opened:after {
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    top: 18px;
    border: 0;
    height: 2px;
    width: 19px;
    background: #fff;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .main-nav .button.menu-opened:before {
    top: 18px;
    background: #fff;
    width: 19px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .main-nav .submenu-button {
    position: absolute;
    z-index: 99;
    right: 0;
    top: 2px;
    display: block;
    border: none;
    height: 46px;
    width: 46px;
    cursor: pointer;
    transform: none;
    margin: 0;
  }
  /*.main-nav .submenu-button.submenu-opened{background:#262626}*/
  .main-nav ul ul .submenu-button {
    height: 34px;
    width: 34px;
  }
  .main-nav .submenu-button:after {
    position: absolute;
    top: 22px;
    right: 19px;
    width: 8px;
    height: 2px;
    display: block;
    background: #ddd;
    content: "";
  }
  .main-nav ul ul .submenu-button:after {
    top: 15px;
    right: 13px;
  }
  .main-nav .submenu-button.submenu-opened:after {
    background: #fff;
  }
  .main-nav .submenu-button:before {
    position: absolute;
    top: 19px;
    right: 22px;
    display: block;
    width: 2px;
    height: 8px;
    background: #ddd;
    content: "";
  }
  .main-nav ul ul .submenu-button:before {
    top: 12px;
    right: 16px;
  }
  .main-nav .submenu-button.submenu-opened:before {
    display: none;
  }
  .main-nav ul ul ul li.active a {
    border-left: none;
  }
  .main-nav > ul > li.has-sub > ul > li.active > a,
  .main-nav > ul ul > li.has-sub > ul > li.active > a {
    border-top: none;
  }
  .banner-caps h1 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  .banner-caps p {
    font-size: 16px;
    color: #fff;
    margin-bottom: 15px;
  }
  .banner-caps ul li {
    font-size: 16px;
    padding: 0 15px 15px;
    margin-left: 0;
  }
  .banner-img video {
    top: -20px;
    left: -20px;
    height: 250px;
  }
  .home-banner {
    padding: 30px 0;
  }
  .footer-left span {
    margin-left: 10px;
    font-family: "Montserrat", sans-serif;
  }
  .timeline-col-img {
    padding: 6px;
    border-radius: 6px;
    width: 135px;
    height: 175px;
  }
  .timeline-col-con {
    width: calc(100% - 135px);
    padding-left: 15px;
  }
  .btn-right {
    max-width: 258px;
    margin-left: 0;
    margin-right: 55px;
  }
  .top-header {
    padding: 5px 0;
  }
  .main-nav li a:after {
    display: none;
  }
  .main-nav ul {
    margin: 0;
    padding: 0;
  }
  .sub-head {
    font-size: 16px;
  }
  .filter-form-feild {
    width: 170px;
  }
  body {
    padding-top: 82px;
  }
  .price {
    font-size: 13px;
    padding: 5px 13px;
    border-radius: 15px;
    white-space: nowrap;
    border: 1px solid #59d386;
  }
  .filter-list .trending-cat-col {
    margin: 5px 0 30px;
    border-radius: 12px;
  }
  .filter-list .trending-cat-img {
    max-height: 200px;
    overflow: hidden;
  }
  .login-img video {
    height: 400px;
    object-fit: cover;
  }
  .profile-img-l {
    width: 100px;
    height: 100px;
  }
  .profile-detail-l {
    width: calc(100% - 100px);
    padding: 0 15px;
  }
  .footer-right a {
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 16px;
    margin-left: 2px;
  }
  .profile-col-left {
    width: calc(100% - 200px);
  }
  .collectin-img {
    margin-right: 0;
    margin-top: 0;
  }
  .flex-row-reverse .collectin-img {
    margin-right: 30px;
    margin-top: 0;
    margin-left: 0;
  }

  .trending-cat-img img {
    height: 310px;
}
}

@media only screen and (max-width: 767px) {
  .top-search,
  .timeline:after,
  .timeline-date:after {
    display: none;
  }
  .timeline-col-left {
    width: calc(100% - 70px);
    padding: 5px;
    margin-bottom: 10px;
  }
  .timeline-col-right {
    width: 70px;
    padding: 15px;
  }
  .timeline .flex-row-reverse {
    -webkit-box-orient: inherit !important;
    -webkit-box-direction: inherit !important;
    -ms-flex-direction: inherit !important;
    flex-direction: inherit !important;
  }
  .timeline-col-in {
    padding: 15px;
    margin: 10px;
    flex-wrap: wrap;
  }
  .timeline-col-img {
    width: 100%;
    height: auto;
  }
  .timeline-col-img img {
    border-radius: 6px;
    width: 100%;
  }
  .timeline-col-con {
    width: 100%;
    padding-left: 0;
    padding-top: 10px;
  }
  .tab-menu ul li a {
    padding: 8px 20px;
    border-radius: 6px;
  }
  .footer-bottom {
    padding-top: 18px;
    margin-top: 10px;
    flex-wrap: wrap;
    text-align: center;
  }
  .footer-left span {
    margin-left: 0;
    display: block;
    padding: 15px 0;
  }
  .footer-left {
    width: 100%;
  }
  .footer-right {
    width: 100%;
  }
  h2.sec-head {
    font-size: 22px;
    margin-bottom: 30px;
  }
  .banner-caps h1 {
    font-size: 28px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .banner-caps ul {
    margin: 0 -15px;
  }
  .banner-btn .btn-fill {
    font-size: 16px;
    min-width: 120px;
    margin-right: 0;
  }
  .timeline-date {
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
  }
  .timeline-time-text p {
    font-size: 14px;
  }
  .timeline-time-text .c-date {
    font-size: 16px;
  }
  .timeline-time-icon {
    width: 40px;
    height: 40px;
    margin-right: 0;
  }
  .timeline-col-con h5 {
    font-size: 18px;
  }
  li.has-sub ul {
    background: none;
    border-radius: 0;
    height: auto;
    visibility: visible;
    box-shadow: none;
    min-width: 180px;
    margin: 0 -10px;
    width: auto;
  }
  li.has-sub ul li {
    border-top: 1px solid rgb(255 255 255 / 20%);
  }
  .logo {
    width: 110px;
  }
  body {
    padding-top: 70px;
  }
  .btn-right {
    padding-top: 8px;
  }
  .main-nav .button {
    top: -51px;
  }
  .main-nav {
    top: 70px;
  }
  .top-header {
    padding: 7px 0;
  }
  .banner-img {
    margin: 10px 0 0 15px;
  }
  .banner-img video {
    top: -10px;
    left: -10px;
    height: 215px;
  }
  .sub-head {
    font-size: 15px;
  }
  .top-filter {
    margin-bottom: 20px;
    flex-wrap: wrap;
    margin-top: 25px;
  }
  .filter-form-feild {
    width: 50%;
  }
  .top-filter h4 {
    color: #55565a;
    font-size: 16px;
    width: 100%;
  }
  .pagination ul li a {
    font-size: 16px;
    padding: 5px 10px;
    width: 36px;
    height: 36px;
    border-radius: 4px;
  }
  .pagination ul li {
    display: block;
    padding: 2px;
  }
  .pagination {
    margin-top: 0;
  }
  .filter-list .trending-cat-img {
    max-height: 300px;
    overflow: hidden;
  }
  .page-banner {
    padding: 35px 0;
  }
  .page-banner h2 {
    font-size: 22px;
  }
  .login-box {
    padding: 20px;
    border-radius: 18px;
    margin: 0 5px;
    height: auto;
  }
  .login-img {
    margin-bottom: 20px;
  }
  .login-img video {
    height: 330px;
    object-fit: cover;
  }
  .profile-col-left {
    width: 100%;
  }
  .profile-detail-l {
    width: 100%;
    padding: 15px 0;
  }
  .profile-img-l {
    margin: 0 auto;
  }
  .prof-btn {
    padding: 8px 10px;
    border-radius: 6px;
    font-size: 13px;
  }
  .profile-col-right .wallet-btn {
    display: block;
  }
  ul.wallet-list li a {
    font-size: 18px;
  }
  .wallet-icon {
    width: 40px;
    height: 40px;
    padding: 10px;
    margin-right: 10px;
  }
  ul.wallet-list li {
    padding: 7px 11px;
    border-radius: 6px;
    margin-bottom: 15px;
  }

}

@media only screen and (max-width: 640px) {
}
@media only screen and (max-width: 467px) {
  .trending-cat-img img {
    height: auto;
}
}
@media only screen and (max-width: 367px) {
}
